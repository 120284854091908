import { IEventEmitter } from '@silvermine/event-emitter';
import { CookieSettings } from './../interfaces/CookieSettings';
import { CookieDataTypes } from './../CookieDataTypes';
import { stringToDOMElement } from '../lib/string-to-dom-element';
import { replaceFinderLinks } from '../lib/replace-finder-links';
import { LegalNoticesClientConfig } from '../interfaces/LegalNoticesClientConfig';
import { CommonTemplateDataProvider } from '../lib/CommonTemplateDataProvider';
import { Transition, TransitionType, DURATIONS_IN_MS } from '../lib/Transition';
import { CommonTemplateData, firstRunPopupChrome, firstRunPopupContent } from './templates';
import { ChromeOverrideOptions, ClientEvents, LegalNoticesClientEventListeners } from '../';
import { createEventEmitterClass } from '../lib/create-event-emitter-class';


interface UIOverrideOptions {
   popup?: ChromeOverrideOptions;
   modal?: ChromeOverrideOptions;
}

interface FirstRunPopupParams {
   eventEmitter: IEventEmitter<LegalNoticesClientEventListeners>;
   clientConfig: LegalNoticesClientConfig;
   uiOverrideOptions?: UIOverrideOptions;
   templateDataProvider: CommonTemplateDataProvider;
}

/**
 * @internal
 */
export enum FirstRunPopupEvents {
   SETTINGS_REQUESTED = 'settings-requested',
}

// eslint-disable-next-line @typescript-eslint/no-type-alias
type FirstRunPopupEventListeners = {
   [FirstRunPopupEvents.SETTINGS_REQUESTED]: () => void;
}

export const FIRST_RUN_POPUP_CSS_CLASS = 'lnc-firstRunPopup';
export const FIRST_RUN_POPUP_CSS_SELECTOR = `.${FIRST_RUN_POPUP_CSS_CLASS}`;

export const FIRST_RUN_POPUP_CONTENT_CSS_CLASS = 'lnc-firstRunPopup-content';
export const FIRST_RUN_POPUP_CONTENT_CSS_SELECTOR = `.${FIRST_RUN_POPUP_CONTENT_CSS_CLASS}`;

/**
 * @internal
 */
export class FirstRunPopup extends createEventEmitterClass<FirstRunPopupEventListeners>() {

   protected _popupOverrideOptions?: ChromeOverrideOptions;
   protected _modalChromeOverrideOptions?: ChromeOverrideOptions;
   protected _clientConfig: LegalNoticesClientConfig;
   protected _eventEmitter: IEventEmitter<LegalNoticesClientEventListeners>;
   protected _el: HTMLElement | undefined;
   protected _templateDataProvider: CommonTemplateDataProvider;
   protected _transition = new Transition();
   protected _closeInProgress = false;

   public constructor(params: FirstRunPopupParams) {
      super();
      this._clientConfig = params.clientConfig;
      this._eventEmitter = params.eventEmitter;
      this._popupOverrideOptions = params.uiOverrideOptions?.popup;
      this._modalChromeOverrideOptions = params.uiOverrideOptions?.modal;
      this._templateDataProvider = params.templateDataProvider;
   }

   public show(): void {
      this._templateDataProvider
         .fetch(this._clientConfig)
         .then((templateData) => {
            let content = this._renderContent(templateData);

            if (this._popupOverrideOptions) {
               const contentEl = this._updatePrivacySettingsLink(stringToDOMElement(content));

               this._popupOverrideOptions.el.innerHTML = '';
               this._popupOverrideOptions.el.appendChild(contentEl);
               this._el = contentEl;
            } else {
               let chrome = this._renderChrome(content, templateData);

               this._el = chrome;
               document.body.prepend(chrome);

               this._transition.trigger(this._el, TransitionType.SLIDE_UP);
            }

            if (this._clientConfig.finderURL) {
               replaceFinderLinks(this._el, this._clientConfig.finderURL);
            }
            this._listenForEvents(this._el);
            this._eventEmitter.emit(ClientEvents.FIRST_RUN_POPUP_SHOWN);
         })
         .catch((e) => {
            this._eventEmitter.emit(ClientEvents.ERROR, e);
         });
   }

   public close(): void {
      if (!this._el || this._closeInProgress) {
         return;
      }

      this._closeInProgress = true;

      const remove = (): void => {
         if (!this._el || !this._el.parentNode) {
            return;
         }
         this._el.parentNode.removeChild(this._el);
         this._el = undefined;
         this._closeInProgress = false;
         this._eventEmitter.emit(ClientEvents.SETTINGS_CLOSED);
      };

      if (this._popupOverrideOptions) {
         this._popupOverrideOptions.close();
         this._closeInProgress = false;
         this._eventEmitter.emit(ClientEvents.FIRST_RUN_POPUP_CLOSED);
      } else {
         this._transition.trigger(this._el, TransitionType.SLIDE_DOWN);
         // Remove the element from the DOM _after_ the transition has finished
         setTimeout(remove, DURATIONS_IN_MS[TransitionType.SLIDE_DOWN]);
      }
   }

   protected _renderChrome(content: string, templateData: CommonTemplateData): HTMLElement {
      const renderedTemplate = firstRunPopupChrome({
         ...templateData,
         cssClasses: `${FIRST_RUN_POPUP_CSS_CLASS}`,
         content,
      });

      return this._updatePrivacySettingsLink(stringToDOMElement(renderedTemplate));
   }

   protected _renderContent(templateData: CommonTemplateData): string {
      return firstRunPopupContent({
         ...templateData,
         cssClasses: `${FIRST_RUN_POPUP_CONTENT_CSS_CLASS} ${templateData.cssClasses}`,
      });
   }

   protected _listenForEvents(el: HTMLElement): void {
      const acceptButton = el.querySelector('.lnc-acceptCookiesButton'),
            declineButton = el.querySelector('.lnc-declineCookiesButton'),
            customizeButton = el.querySelector('.lnc-customizeCookiesButton');

      if (acceptButton) {
         acceptButton.addEventListener('click', () => {
            this._updateCookieSettings({
               [CookieDataTypes.STRICTLY_NECESSARY]: true,
               [CookieDataTypes.FUNCTIONAL]: true,
               [CookieDataTypes.DIAGNOSTIC]: true,
               [CookieDataTypes.USAGE]: true,
            });
            this.close();
         });
      }

      if (declineButton) {
         declineButton.addEventListener('click', () => {
            this._updateCookieSettings({
               [CookieDataTypes.STRICTLY_NECESSARY]: true,
               [CookieDataTypes.FUNCTIONAL]: false,
               [CookieDataTypes.DIAGNOSTIC]: false,
               [CookieDataTypes.USAGE]: false,
            });
            this.close();
         });
      }

      if (customizeButton) {
         customizeButton.addEventListener('click', () => {
            this.close();
            this.emit(FirstRunPopupEvents.SETTINGS_REQUESTED);
         });
      }

      if (this._el) {
         this._el.addEventListener('click', (e) => {
            const target = e.target as HTMLElement | null,
                  isAnchor = target && target.tagName.toLowerCase() === 'a',
                  href = (target && isAnchor) ? (target.getAttribute('href') || '') : '';

            if (isAnchor && href.endsWith('#privacy-settings')) {
               e.preventDefault();
               this.close();
               this.emit(FirstRunPopupEvents.SETTINGS_REQUESTED);
            }
         });
      }
   }

   protected _updateCookieSettings(cookieSettings: CookieSettings): void {
      this._clientConfig.storage.set(cookieSettings)
         .then(() => {
            this._eventEmitter.emit(ClientEvents.SETTINGS_CHANGED, cookieSettings);
         })
         .catch((e) => {
            if (e) {
               this._eventEmitter.emit(ClientEvents.ERROR, e);
            }
         });
   }

   /**
    * Removes the full URL from the privacy-settings link within the popup content,
    * replacing it with #privacy-settings.
    *
    * The privacy-settings link contains a finder URL, so if a user were to right-click
    * and open the URL in a new window, they would be taken to jw.org. Therefore, we
    * replace the full link with a hash to keep the user on the same site.
    */
   protected _updatePrivacySettingsLink(el: HTMLElement): HTMLElement {
      el.querySelectorAll('a').forEach((a) => {
         if (a.href && a.href.endsWith('#privacy-settings')) {
            a.href = '#privacy-settings';
         }
      });

      return el;
   }
}
