import { isEnumValue } from '@silvermine/toolbox';
import { IEventEmitter } from '@silvermine/event-emitter';
import { CookieSettings } from './../interfaces/CookieSettings';
import { CookieDataTypes } from '../CookieDataTypes';
import { settingsContent } from './templates';
import { Modal, ModalEvents } from './Modal';
import { CommonTemplateData } from './templates/index';
import { stringToDOMElement } from '../lib/string-to-dom-element';
import { LegalNoticesClientConfig } from '../interfaces/LegalNoticesClientConfig';
import { CommonTemplateDataProvider } from '../lib/CommonTemplateDataProvider';
import { ChromeOverrideOptions, ClientEvents, LegalNoticesClientEventListeners } from '../';
import { replaceFinderLinks } from '../lib/replace-finder-links';
import { hasUserChangedSettings } from '../lib/has-user-changed-settings';
import { DEFAULT_COOKIE_SETTINGS } from '../default-cookie-settings';
import { withDefaultSettings } from '../lib/with-default-settings';

export const SETTINGS_CSS_CLASS = 'lnc-settings';
export const SETTINGS_CSS_SELECTOR = `.${SETTINGS_CSS_CLASS}`;

export const SETTINGS_CONTENT_CSS_CLASS = 'lnc-settings-content';
export const SETTINGS_CONTENT_CSS_SELECTOR = `.${SETTINGS_CONTENT_CSS_CLASS}`;

export const SETTINGS_OVERLAY_CSS_CLASS = `${SETTINGS_CSS_CLASS}-overlay`;

interface SettingsModalParams {
   eventEmitter: IEventEmitter<LegalNoticesClientEventListeners>;
   clientConfig: LegalNoticesClientConfig;
   chromeOverrideOptions?: ChromeOverrideOptions;
   templateDataProvider: CommonTemplateDataProvider;
}

/**
 * @internal
 */
export class SettingsModal {

   protected _chromeOverrideOptions?: ChromeOverrideOptions;
   protected _clientConfig: LegalNoticesClientConfig;
   protected _eventEmitter: IEventEmitter<LegalNoticesClientEventListeners>;
   protected _contentEl: HTMLElement | undefined;
   protected _cookieSettings!: CookieSettings;
   protected _pendingSettingsUpdate?: Promise<void>;
   protected _modal: Modal;
   protected _templateDataProvider: CommonTemplateDataProvider;

   public constructor(params: SettingsModalParams) {
      this._clientConfig = params.clientConfig;
      this._eventEmitter = params.eventEmitter;
      this._chromeOverrideOptions = params.chromeOverrideOptions;
      this._templateDataProvider = params.templateDataProvider;
      this._modal = new Modal({ cssClasses: [ SETTINGS_CSS_CLASS ] });

      this._modal.on(ModalEvents.CLOSED, () => {
         this._eventEmitter.emit(ClientEvents.SETTINGS_CLOSED);
      });
   }

   public show(): void {
      if (!this._chromeOverrideOptions) {
         // Show the modal with the loading indicator immediately
         this._modal.show();
      }

      // Because this method doesn't return a Promise but using async/await makes the code
      // easier to read, we implement most of the method in a local async function:
      const show = async (): Promise<void> => {
         const cookieSettings = withDefaultSettings(await this._clientConfig.storage.get()),
               templateData = await this._templateDataProvider.fetch(this._clientConfig),
               content = this._renderContent(templateData),
               contentEl = stringToDOMElement(content);

         this._cookieSettings = cookieSettings;
         this._contentEl = contentEl;

         if (this._chromeOverrideOptions) {
            this._chromeOverrideOptions.el.innerHTML = '';
            this._chromeOverrideOptions.el.appendChild(this._contentEl);
         } else {
            this._updateModalWithLoadedData(templateData);
            this._modal.setContent(this._contentEl);
         }

         if (this._clientConfig.finderURL) {
            replaceFinderLinks(this._contentEl, this._clientConfig.finderURL);
         }
         this._synchronizeCheckboxUI(cookieSettings);
         this._listenForEvents(this._contentEl);
         this._eventEmitter.emit(ClientEvents.SETTINGS_SHOWN, cookieSettings);
      };

      show().catch((e) => {
         this._eventEmitter.emit(ClientEvents.ERROR, e);
      });
   }

   public close(): void {
      this._contentEl = undefined;
      if (this._chromeOverrideOptions) {
         this._chromeOverrideOptions.close();
         this._eventEmitter.emit(ClientEvents.SETTINGS_CLOSED);
      } else {
         this._modal.close();
      }
   }

   protected _listenForEvents(el: HTMLElement): void {
      const allowAllButton = el.querySelector('.lnc-allowAllButton'),
            declineAllButton = el.querySelector('.lnc-declineAllButton'),
            confirmButton = el.querySelector('.lnc-confirmButton');

      if (allowAllButton) {
         allowAllButton.addEventListener('click', () => {
            this._updateCookieSettings({
               [CookieDataTypes.STRICTLY_NECESSARY]: true,
               [CookieDataTypes.FUNCTIONAL]: true,
               [CookieDataTypes.DIAGNOSTIC]: true,
               [CookieDataTypes.USAGE]: true,
            });
            this.close();
         });
      }

      if (declineAllButton) {
         declineAllButton.addEventListener('click', () => {
            this._updateCookieSettings({
               [CookieDataTypes.STRICTLY_NECESSARY]: true,
               [CookieDataTypes.FUNCTIONAL]: false,
               [CookieDataTypes.DIAGNOSTIC]: false,
               [CookieDataTypes.USAGE]: false,
            });
            this.close();
         });
      }

      if (confirmButton) {
         confirmButton.addEventListener('click', async () => {
            // If the user presses the "confirm" button without having changed any
            // checkboxes, we should treat this as if they are confirming the checkboxes
            // in their default state.
            if (!hasUserChangedSettings(await this._clientConfig.storage.get())) {
               this._updateCookieSettings(DEFAULT_COOKIE_SETTINGS);
            }
            this.close();
         });
      }

      el.addEventListener('change', (e) => {
         const checkbox = e.target as HTMLInputElement;

         if (!checkbox || checkbox.type !== 'checkbox') {
            return;
         }

         const cookieDataType = checkbox.dataset.cookieType;

         if (isEnumValue(CookieDataTypes, cookieDataType)) {
            this._updateCookieSettings({
               ...this._cookieSettings,
               [cookieDataType]: checkbox.checked,
            });
         } else {
            const values = Object.keys(CookieDataTypes).map((k) => { return CookieDataTypes[k as keyof typeof CookieDataTypes]; });

            this._eventEmitter.emit(
               ClientEvents.ERROR,
               new Error(
                  'A settings modal checkbox is implemented incorrectly in the legal notices client library. ' +
                  `The data-cookie-type property on the checkbox element is ${checkbox.dataset.cookieType}, ` +
                  `which is not a valid cookie type. Valid types are: ${values.join(', ')}.`
               )
            );
         }
      });
   }

   protected _updateCookieSettings(newCookieSettings: CookieSettings): void {
      if (!this._pendingSettingsUpdate) {
         this._pendingSettingsUpdate = Promise.resolve();
      }

      // Ensure that each cookie setting update is sent only after the previous one has
      // finished to prevent inconsistent data.
      this._pendingSettingsUpdate = this._pendingSettingsUpdate
         .finally(async () => {
            this._cookieSettings = newCookieSettings;

            return this._clientConfig.storage.set(newCookieSettings);
         })
         .then(() => {
            this._eventEmitter.emit(ClientEvents.SETTINGS_CHANGED, this._cookieSettings);
         })
         .catch(async (e: Error) => {
            const cookieSettings = await this._clientConfig.storage.get();

            // If an error happened, synchronize the UI with the stored state
            this._cookieSettings = cookieSettings;
            this._synchronizeCheckboxUI(cookieSettings);

            if (e) {
               this._eventEmitter.emit(ClientEvents.ERROR, e);
            }
         });
   }

   /**
    * Update the checkboxes in the UI to match the state of the provided cookieSettings
    */
   protected _synchronizeCheckboxUI(cookieSettings: CookieSettings): void {
      const checkboxes = document.querySelectorAll('.lnc-checkbox input[type=checkbox]');

      checkboxes.forEach((el) => {
         const checkbox = el as HTMLInputElement,
               cookieDataType = checkbox.dataset.cookieType;

         if (!isEnumValue(CookieDataTypes, cookieDataType)) {
            return;
         }

         if (checkbox.checked !== cookieSettings[cookieDataType]) {
            checkbox.checked = !!cookieSettings[cookieDataType];
         }
      });
   }

   protected _renderContent(templateData: CommonTemplateData): string {
      return settingsContent({
         ...templateData,
         cssClasses: `${SETTINGS_CONTENT_CSS_CLASS} ${templateData.cssClasses}`,
      });
   }

   protected _updateModalWithLoadedData(templateData: CommonTemplateData): void {
      this._modal.setTitle(templateData.translations.privacySettingsHeading);
      this._modal.setCloseButtonARIALabel(templateData.translations.closeButtonLabel);
      this._modal.setLanguageAttributes(templateData.languageSummary);
      this._modal.setLanguageClasses((this._clientConfig.languageClassesMaker(templateData.languageSummary) || '').split(' '));
   }
}
