import modalTemplate from './modal.html';
import { makeTemplate } from '@silvermine/toolbox';
import { LanguageSummary } from '../../interfaces';
import firstRunPopupChromeTemplate from './first-run-popup-chrome.html';
import firstRunPopupContentTemplate from './first-run-popup-content.html';
import settingsContentTemplate from './settings-content.html';

const TEMPLATES = {
   firstRunPopupChromeTemplate,
   firstRunPopupContentTemplate,
   settingsContentTemplate,
   modalTemplate,
} as const;

let templateCache: Record<string, Function> = {};

function getTemplateFunction(name: keyof typeof TEMPLATES): Function {
   if (!templateCache[name]) {
      templateCache[name] = makeTemplate(TEMPLATES[name]);
   }

   return templateCache[name];
}

export interface CommonTemplateData {
   translations: Record<string, string>;
   languageSummary: LanguageSummary;
   cssClasses: string;
}

export interface FirstRunPopupChromeTemplateData extends CommonTemplateData {
   content: string;
}

export function firstRunPopupChrome(data: FirstRunPopupChromeTemplateData): string {
   const templateFn = getTemplateFunction('firstRunPopupChromeTemplate');

   return templateFn(data);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FirstRunPopupContentTemplateData extends CommonTemplateData {
   // Intentionally empty until more properties are added
}

export function firstRunPopupContent(data: FirstRunPopupContentTemplateData): string {
   const templateFn = getTemplateFunction('firstRunPopupContentTemplate');

   return templateFn(data);
}

export interface ModalChromeTemplateData {
   closeIconSVG: string;
}

export function modalChrome(data: ModalChromeTemplateData): string {
   const templateFn = getTemplateFunction('modalTemplate');

   return templateFn(data);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SettingsContentTemplateData extends CommonTemplateData {
   // Intentionally empty until more properties are added
}

export function settingsContent(data: SettingsContentTemplateData): string {
   const templateFn = getTemplateFunction('settingsContentTemplate');

   return templateFn(data);
}
