/**
 * Represents the categories of cookie data.
 */
export enum CookieDataTypes {

   /**
    * Strictly necessary cookies are essential for the functionality of the website or
    * app. They are always enabled.
    */
   STRICTLY_NECESSARY = 'STRICTLY_NECESSARY',

   /**
    * User preferences such as language or display preferences. These cookies are used to
    * provide a better user experience.
    */
   FUNCTIONAL = 'FUNCTIONAL',

   /**
    * Data collected when the website or app experiences an error.
    */
   DIAGNOSTIC = 'DIAGNOSTIC',

   /**
    * Data about how the website or app is used.
    */
   USAGE = 'USAGE',
}
