import { isUndefined } from '@silvermine/toolbox';
import { CookieDataTypes } from '../CookieDataTypes';
import { CookieSettings } from '../interfaces/CookieSettings';

/**
 * @internal
 * @returns `true` if user has modified the cookie settings
 */
export function hasUserChangedSettings(settings: CookieSettings): boolean {
   const settingsKeys = Object.keys(settings) as (keyof typeof settings)[];

   let hasChanged = false;

   for (const key of settingsKeys) {
      const value = settings[key];

      if (!isUndefined(value) && key !== CookieDataTypes.STRICTLY_NECESSARY) {
         hasChanged = true;
         break;
      }
   }

   return hasChanged;
}
