import closeIconSVG from '../../icons/close.svg';
import * as focusTrap from 'focus-trap';
import { modalChrome } from './templates';
import { LanguageSummary } from './../interfaces/LanguageSummary';
import { stringToDOMElement } from '../lib/string-to-dom-element';
import { GlobalEventListeners } from '../lib/GlobalEventListeners';
import { createEventEmitterClass } from '../lib/create-event-emitter-class';
import * as bodyScrollLock from 'body-scroll-lock';
import { DURATIONS_IN_MS, Transition, TransitionType } from '../lib/Transition';


export const MODAL_CSS_CLASS = 'lnc-modal';
export const MODAL_CSS_SELECTOR = `.${MODAL_CSS_CLASS}`;

export const MODAL_OVERLAY_CSS_CLASS = `${MODAL_CSS_CLASS}-overlay`;

interface ModalOptions {
   cssClasses?: string[];
}

/**
 * @internal
 */
export enum ModalEvents {
   CLOSED = 'modal-closed',
}

// eslint-disable-next-line @typescript-eslint/no-type-alias
type ModalEventListeners = {
   [ModalEvents.CLOSED]: () => void;
}

/**
 * @internal
 */
export class Modal extends createEventEmitterClass<ModalEventListeners>() {
   protected _overlayEl: HTMLElement | undefined;
   protected _modalEl: HTMLElement | undefined;
   protected _transition = new Transition();
   protected _globalListeners = new GlobalEventListeners();
   protected _closeInProgress = false;
   protected _languageClasses: string[] = [];
   protected _options: ModalOptions;
   protected _focusTrap?: focusTrap.FocusTrap;
   protected _lastKnownBodyRightValue: string | undefined;

   public constructor(options?: ModalOptions) {
      super();
      this._options = options || {};
   }

   public show(): void {
      if (this._overlayEl) {
         return;
      }
      let chrome = this._render();

      this._overlayEl = chrome;
      this._modalEl = chrome.querySelector(MODAL_CSS_SELECTOR) as HTMLElement;
      this._focusTrap = focusTrap.createFocusTrap(this._modalEl, { allowOutsideClick: true });

      const customCSSClasses = this._options.cssClasses || [];

      customCSSClasses.forEach((c) => {
         if (this._modalEl) {
            this._modalEl.classList.add(c);
         }
      });

      // We must start the transition _before_ adding the element to the DOM so that the
      // element has the "transition start" class before it appears in the DOM.
      this._transition.trigger(this._overlayEl, TransitionType.FADE_IN);
      window.document.body.appendChild(this._overlayEl);

      this._listenForGlobalEventsThatCloseModal();
      this._listenForEvents(chrome);
      this._focusTrap.activate();

      const body = chrome.querySelector('.lnc-modal-body');

      if (!body) {
         throw new Error('Expected the modal to contain a ".lnc-modal-body" element, but it did not.');
      }

      this._lockBodyScrolling(body);
   }

   public close(): void {
      if (!this._overlayEl || this._closeInProgress) {
         return;
      }

      this._closeInProgress = true;
      this._transition.trigger(this._overlayEl, TransitionType.FADE_OUT);

      // Remove the element from the DOM _after_ the transition has finished
      setTimeout(() => {
         if (!this._overlayEl || !this._overlayEl.parentNode || !this._modalEl) {
            return;
         }
         this._unlockBodyScrolling();
         this._overlayEl.parentNode.removeChild(this._overlayEl);
         this._overlayEl = undefined;
         this._modalEl = undefined;
         this._closeInProgress = false;
         if (this._focusTrap) {
            this._focusTrap.deactivate();
         }
         this.emit(ModalEvents.CLOSED);
      }, DURATIONS_IN_MS[TransitionType.FADE_OUT]);

      this._globalListeners.removeAll();
   }

   public setContent(content: HTMLElement | string): void {
      const modalBody = this._overlayEl?.querySelector('.lnc-modal-body');

      if (!modalBody) {
         return;
      }

      if (typeof content === 'string') {
         modalBody.innerHTML = content;
      } else {
         modalBody.innerHTML = '';
         modalBody.appendChild(content);
      }
   }

   public setLanguageAttributes(languageSummary: LanguageSummary): void {
      if (!this._overlayEl) {
         return;
      }

      const el = this._overlayEl.querySelector(MODAL_CSS_SELECTOR);

      if (el) {
         el.setAttribute('lang', languageSummary.locale);
         el.setAttribute('xml:lang', languageSummary.locale);
         el.setAttribute('dir', languageSummary.direction);
      }
   }

   public setLanguageClasses(languageClasses: string[]): void {
      if (!this._modalEl) {
         return;
      }

      this._languageClasses.forEach((c) => {
         if (this._modalEl) {
            this._modalEl.classList.remove(c);
         }
      });
      languageClasses.forEach((c) => {
         if (this._modalEl) {
            this._modalEl.classList.add(c);
         }
      });
      this._languageClasses = languageClasses;
   }

   public setTitle(title: string): void {
      if (!this._modalEl) {
         return;
      }
      const heading = this._modalEl.querySelector('.lnc-modal-header-heading');

      if (heading) {
         heading.innerHTML = title;
      }
   }

   public setCloseButtonARIALabel(label: string): void {
      if (!this._modalEl) {
         return;
      }
      const labelEl = this._modalEl.querySelector('.lnc-modal-header-closeButton > .lnc-screenReaderOnly');

      if (labelEl) {
         labelEl.innerHTML = label;
      }
   }

   protected _render(): HTMLElement {
      const renderedTemplate = modalChrome({
         closeIconSVG,
      });

      return stringToDOMElement(renderedTemplate);
   }

   protected _listenForEvents(el: HTMLElement): void {
      const closeButton = el.querySelector('.lnc-modal-header-closeButton'),
            overlay = el.classList.contains(MODAL_OVERLAY_CSS_CLASS) ? el : null;

      if (closeButton) {
         closeButton.addEventListener('click', () => { this.close(); });
      }

      if (overlay) {
         overlay.addEventListener('click', (evt: MouseEvent) => {
            const target = evt.target as Node | undefined;

            // Only close the modal if someone clicks directly on the overlay
            if (overlay && target && overlay.isSameNode(target)) {
               this.close();
            }
         });
      }
   }

   protected _listenForGlobalEventsThatCloseModal(): void {
      this._globalListeners.add(() => { return window; }, 'keyup', (evt: KeyboardEvent) => {
         if (evt.key === 'Escape') {
            this.close();
         }
      });
   }

   protected _lockBodyScrolling(keepScrollableEl: Element): void {
      // The body-scroll-lock library has a bug: In iOS 16 Webkit, when body-scroll-lock
      // locks the body scrolling, it sets `position: fixed; left: 0; top: 0`. When the
      // page contains a very wide element, such as a carousel, this causes the page to
      // become as wide as the widest descendant element on the page. We can fix this by
      // also setting a `right: 0px` style to constrain the body width to the width of the
      // viewport.
      //
      // NOTE: We can remove this code if/when body-scroll-lock release a new version that
      // fixes this issue.
      this._lastKnownBodyRightValue = window.document.body.style.right;
      // We must set the `right` property _before_ locking the body scroll to prevent the
      // the body from ever becoming too wide.
      window.document.body.style.right = '0px';

      // The `body` parameter is the element that you want to keep scrollable when
      // scrolling is locked
      bodyScrollLock.disableBodyScroll(keepScrollableEl);

      // Wait until next tick before removing `style.right` so that body.style has updated
      // after `disableBodyScroll` is finished.
      window.requestAnimationFrame(() => {
         // body-scroll-lock only uses the `position: fixed` method on iOS. Therefore,
         // remove the `right` property fix if it's not needed.
         if (window.document.body.style.position !== 'fixed') {
            window.document.body.style.right = '';
         }
      });
   }

   protected _unlockBodyScrolling(): void {
      let currentPosition = window.document.body.style.position;

      bodyScrollLock.clearAllBodyScrollLocks();

      // Wait until next tick before removing `style.right` so that body.style has updated
      // after `clearAllBodyScrollLocks` is finished.
      window.requestAnimationFrame(() => {
         // See comment in `_lockBodyScrolling` above.
         if (currentPosition === 'fixed') {
            window.document.body.style.right = this._lastKnownBodyRightValue || '';
         }
      });
   }
}
