import { isArray } from '@silvermine/toolbox';
import { LanguageSummary } from './../interfaces/LanguageSummary';
import { CommonTemplateData } from '../ui/templates';
import { LegalNoticesClientConfig } from '../interfaces';


/**
 * @internal
 */
export interface TranslationsCollection {
   language: LanguageSummary;
   translations: Record<string, string>;
}

/**
 * @internal
 */
export interface ErrorDetails {
   id: string;
   title: string;
   detail: string;
   status: number;
}

/**
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type ErrorResponse = Array<ErrorDetails>;

function isErrorResponse(response: TranslationsCollection | ErrorResponse): response is ErrorResponse {
   if (!isArray(response) || response.length === 0) {
      return false;
   }

   return typeof response[0].title === 'string' && typeof response[0].status === 'number';
}

/**
 * @internal
 */
export class CommonTemplateDataProvider {

   protected _cachedTranslations?: TranslationsCollection;

   public async fetch(clientConfig: LegalNoticesClientConfig): Promise<CommonTemplateData> {
      const response = await this._getTranslationsCollection(clientConfig);

      if (isErrorResponse(response)) {
         throw new Error(
            response.reduce((memo, e) => {
               return `${memo}\n${e.title}: ${e.detail}`;
            }, '')
         );
      }

      return {
         translations: response.translations,
         languageSummary: response.language,
         cssClasses: clientConfig.languageClassesMaker(response.language),
      };
   }

   protected async _getTranslationsCollection(clientConfig: LegalNoticesClientConfig): Promise<TranslationsCollection | ErrorResponse> {
      if (this._cachedTranslations && clientConfig.languageCode === this._cachedTranslations.language.languageCode) {
         return this._cachedTranslations;
      }
      const url = this._buildURL(clientConfig.i18nURL, 'translation-collections', 'legal-notices-v2', clientConfig.languageCode);

      let response = await fetch(url);

      if (this._is5xxResponse(response)) {
         // Re-try 500 errors once. The tech behind our APIs have occasional connection
         // errors that result in a 500 response, and re-trying often results in a
         // success.
         response = await fetch(url);

         if (this._is5xxResponse(response)) {
            throw new Error('Sorry, there was a server error. Please try again later.');
         }
      }

      const content = await response.json();

      if (isErrorResponse(content)) {
         return content;
      }

      const cachedTranslationCollection: TranslationsCollection = content;

      // Return a blank string for any undefined translation string keys. Otherwise, keys
      // that don't exist yet in the i18n API response will show up as the string
      // "undefined" in the UI.
      if (typeof window !== 'undefined' && typeof window.Proxy !== 'undefined') {
         cachedTranslationCollection.translations = new Proxy(cachedTranslationCollection.translations, {
            get(target, key, receiver) {
               return Reflect.get(target, key, receiver) || '';
            },
         });
      }

      this._cachedTranslations = cachedTranslationCollection;

      return cachedTranslationCollection;
   }

   protected _is5xxResponse(response: Response): boolean {
      return response.status >= 500 && response.status <= 599;
   }

   protected _buildURL(...urlParts: string[]): string {
      let url = '';

      for (const part of urlParts) {
         url += part;

         if (url[url.length - 1] !== '/') {
            url += '/';
         }
      }

      // Remove the last trailing slash
      return url.substring(0, url.length - 1);
   }
}
