/**
 * @internal
 */
interface RegisteredEventListener {
   getTarget: () => GlobalEventHandlers;
   eventName: string;
   fn: (evt: any) => void;
}

/**
 * @internal
 */
export class GlobalEventListeners {

   protected _eventListeners: RegisteredEventListener[] = [];

   public add(
      getTarget: RegisteredEventListener['getTarget'],
      eventName: RegisteredEventListener['eventName'],
      fn: RegisteredEventListener['fn']
   ): void {
      getTarget().addEventListener(eventName, fn);
      this._eventListeners.push({ getTarget, fn, eventName });
   }

   public removeAll(): void {
      for (const listener of this._eventListeners) {
         const target = listener.getTarget();

         if (target) {
            target.removeEventListener(listener.eventName, listener.fn);
         }
      }
   }
}
