import Cookies from 'js-cookie';
import { CookieDataTypes } from './CookieDataTypes';
import { CookieSettings } from './interfaces/CookieSettings';
import { CookieSettingsStorageProvider } from './interfaces/CookieSettingsStorageProvider';


export function getCookieName(cookieDataType: CookieDataTypes): string {
   return `cookieConsent-${cookieDataType}`;
}

function getStoredValue(cookieDataType: CookieDataTypes): boolean | undefined {
   const value = Cookies.get(getCookieName(cookieDataType));

   if (value === 'true') {
      return true;
   } else if (value === 'false') {
      return false;
   }

   return undefined;
}

/**
 * The default settings storage provider stores cookie settings as a series of [HTTP
 * cookies](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies). Each setting is
 * stored under a unique cookie name that follows the pattern:
 * `cookieConsent-${CATEGORY}` with a value of either 'true', or 'false'. 'true' means
 * the user has opted into the category, 'false' means they have opted out, and if there
 * is no cookie at all it means they have not responded yet.
 *
 * For example, the setting for whether the user has opted into the "diagnostic" cookie
 * category is stored as a cookie with the name `cookieConsent-DIAGNOSTIC` with a value of
 * 'true'.
 *
 * When the user clicks "Accept All" in the UI, this default storage provider will store
 * a setting for every {@link CookieDataTypes} with a value of 'true'. When the user
 * clicks "Deny All", the same thing happens but with a value of 'false' for each cookie.
 */
export class DefaultCookieSettingsStorageProvider implements CookieSettingsStorageProvider {

   public async get(): Promise<CookieSettings> {
      return {
         STRICTLY_NECESSARY: true, // STRICTLY_NECESSARY cookies are always allowed
         FUNCTIONAL: getStoredValue(CookieDataTypes.FUNCTIONAL),
         DIAGNOSTIC: getStoredValue(CookieDataTypes.DIAGNOSTIC),
         USAGE: getStoredValue(CookieDataTypes.USAGE),
      };
   }

   public async set(settings: CookieSettings): Promise<void> {
      for (const cookieDataType of Object.keys(settings) as CookieDataTypes[]) {
         const value = settings[cookieDataType];

         // We do not allow deleting settings, so if we pass { FUNCTIONAL: undefined }
         // it has no effect.
         if (value === undefined) {
            continue;
         }

         if (cookieDataType === CookieDataTypes.STRICTLY_NECESSARY) {
            Cookies.set(getCookieName(cookieDataType), 'true', { expires: 365 });
         } else {
            Cookies.set(getCookieName(cookieDataType), `${value}`, { expires: 365 });
         }
      }
   }
}
