/**
 * @module
 * @internal
 */
import { Constructor } from '../interfaces/Constructor';
import { EventEmitterMixin, IEventEmitter } from '@silvermine/event-emitter';

/**
 * @internal
 */
export function createEventEmitterClass<EventListeners>(): Constructor<IEventEmitter<EventListeners>> {
   class Base {}

   Object.assign(Base.prototype, EventEmitterMixin);

   return Base as Constructor<IEventEmitter<EventListeners>>;
}
