import { CookieSettings } from './interfaces/CookieSettings';

/**
 * Lists all of the events that could be emitted by the {@link LegalNoticesClient}. Use
 * the {@link LegalNoticesClient.on} or {@link LegalNoticesClient.once} methods to listen
 * to these events.
 */
export enum ClientEvents {
   // Developer note: If you add an event to this enum, add a corresponding event listener
   // type to the `LegalNoticesClientEventListeners` type below.

   /**
    * Emitted if you call the `LegalNoticesClient`'s `run()` method and the first-run
    * popup is shown. If you override the default popup by passing an `HTMLElement` to the
    * `run` method, this event is emitted when the first-run popup's content is rendered
    * into the provided `HTMLElement`. See the documentation for the `run` method for
    * details on overriding the default popup.
    */
   FIRST_RUN_POPUP_SHOWN = 'first-run-popup-shown',

   /**
    * Emitted when the first-run popup is closed via user interaction. If you override the
    * default popup by passing an `HTMLElement` to the `run` method, this event will not
    * be emitted.
    */
   FIRST_RUN_POPUP_CLOSED = 'first-run-popup-closed',

   /**
    * Emitted when you call the `LegalNoticesClient`'s `showSettings()` method and the
    * settings modal is shown. If you override the default modal by passing an
    * `HTMLElement` to the `showSettings` method, this event is emitted when the modal's
    * content is rendered into the provided `HTMLElement`. See the documentation for the
    * `showSettings` method for details on overriding the default settings modal.
    */
   SETTINGS_SHOWN = 'settings-shown',

   /**
    * Emitted when the settings modal is closed via user interaction. If you override the
    * default modal by passing an `HTMLElement` to the `run` method, this event will not
    * be emitted.
    */
   SETTINGS_CLOSED = 'settings-closed',

   /**
    * Emitted when the cookie settings have changed, _after_ the settings have been
    * persisted with the configured storage provider's `set` method.
    */
   SETTINGS_CHANGED = 'settings-changed',

   /**
    * Emitted when the user selects the "Accept All" option in the first-run popup. This
    * event is followed by a `SETTINGS_CHANGED` event once the settings have been
    * persisted.
    */
   ACCEPT_ALL = 'accept-all',

   /**
    * Emitted when the user selects the "Decline All" option in the first-run popup. This
    * event is followed by a `SETTINGS_CHANGED` event once the settings have been
    * persisted.
    */
   DECLINE_ALL = 'decline-all',

   /**
    * Emitted when there is an internal error while the legal notices client is running.
    * Errors may occur when loading legal notices translation strings or when persisting
    * cookie settings, for example.
    */
   ERROR = 'error',
}

/**
 * A type that maps between {@link ClientEvents} and event listener function types. The
 * legal notices client can only emit events that are listed here.
 */
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type LegalNoticesClientEventListeners = {
   [ClientEvents.FIRST_RUN_POPUP_SHOWN]: () => void;
   [ClientEvents.FIRST_RUN_POPUP_CLOSED]: () => void;
   [ClientEvents.SETTINGS_SHOWN]: (settings: CookieSettings) => void;
   [ClientEvents.SETTINGS_CLOSED]: () => void;
   [ClientEvents.SETTINGS_CHANGED]: (settings: CookieSettings) => void;
   [ClientEvents.ACCEPT_ALL]: (settings: CookieSettings) => void;
   [ClientEvents.DECLINE_ALL]: (settings: CookieSettings) => void;
   [ClientEvents.ERROR]: (e: Error) => void;
}
