/**
 * @internal
 */
export enum TransitionType {
   FADE_IN = 'fadeIn',
   FADE_OUT = 'fadeOut',
   SLIDE_UP = 'slideUp',
   SLIDE_DOWN = 'slideDown',
}

/**
 * These durations should match the duration of the corresponding transition in
 * src/scss/_transitions.scss
 *
 * @internal
 */
export const DURATIONS_IN_MS: { [K in TransitionType]: number } = {
   [TransitionType.FADE_IN]: 200,
   [TransitionType.FADE_OUT]: 200,
   [TransitionType.SLIDE_UP]: 600,
   [TransitionType.SLIDE_DOWN]: 400,
};

/**
 * @internal
 */
export class Transition {

   public trigger(el: HTMLElement, transitionType: TransitionType): void {
      if (!el) {
         return;
      }
      const startClass = `lnc-transition--${transitionType}-start`,
            endClass = `lnc-transition--${transitionType}-end`;

      el.classList.add(startClass);
      el.style.transitionDuration = `${DURATIONS_IN_MS[transitionType]}ms`;

      setTimeout(() => {
         if (el) {
            el.classList.remove(startClass);
            el.classList.add(endClass);
         }
      }, 0);

      setTimeout(() => {
         if (el) {
            el.classList.remove(endClass);
            el.style.transitionDuration = '';
         }
      }, DURATIONS_IN_MS[transitionType]); // This should match the transition duration
   }
}
