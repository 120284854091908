import { isUndefined } from '@silvermine/toolbox';
import { CookieSettings } from '../interfaces/CookieSettings';
import { CookieDataTypes } from '../CookieDataTypes';
import { DEFAULT_COOKIE_SETTINGS } from '../default-cookie-settings';

export function withDefaultSettings(settings: CookieSettings): CookieSettings {
   let withDefaults: CookieSettings = {} as CookieSettings;

   const settingsKeys = Object.keys(CookieDataTypes) as (keyof typeof CookieDataTypes)[];

   for (const key of settingsKeys) {
      withDefaults[key] = isUndefined(settings[key]) ? DEFAULT_COOKIE_SETTINGS[key] : settings[key];
   }

   return withDefaults;
}
