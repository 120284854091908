// The i18n API will always return links that use this finder URL
// ('https://www.jw.org/finder'). We replace these links with whatever is specified in
// LegalNoticesClientConfig.finderURL to support alternate domains.
//
// The default value of `finderURL` is 'https://www.jw.org/open', so by default, this
// client replaces /finder with /open links. When a user visits a /finder link in their
// browser, JW Library will attempt to open the link. /open links, however, will always
// open in their browser and on jw.org, which is what we need in almost all clients that
// use legal notices client.
const FINDER_LINK_URL = 'https://www.jw.org/finder';

export function replaceFinderLinks(htmlToModify: HTMLElement, newFinderLinkURL: string): void {
   if (newFinderLinkURL === FINDER_LINK_URL) {
      return;
   }

   htmlToModify.querySelectorAll('a').forEach((a) => {
      const href = a.getAttribute('href') || '';

      if (href.indexOf(FINDER_LINK_URL) >= 0) {
         a.setAttribute('href', href.replace(FINDER_LINK_URL, newFinderLinkURL));
      }
   });
}
