// NOTE: If this module name changes, update the "indexPageObjectName" setting in
// typedoc.json.
/**
* @module LegalNoticesClient
*/

// All classes, enums, interfaces, types, etc exported here are part of this library's
// public interface.
//
// NOTE: When adding an export for something that is not a type-only construct (e.g. a
// class, constant, function, enum, etc.) you must also add the export to index.esm.ts.
export * from './interfaces';
export * from './ClientEvents';
export { CookieDataTypes } from './CookieDataTypes';
export * from './LegalNoticesClient';
export { DefaultCookieSettingsStorageProvider } from './DefaultCookieSettingsStorageProvider';
