import { SettingsModal } from './ui/SettingsModal';
import { UnwrapPromise } from './interfaces/UnwrapPromise';
import { DEFAULT_CONFIG } from './default-client-config';
import { CookieDataTypes } from './CookieDataTypes';
import { isString, isUndefined } from '@silvermine/toolbox';
import { createEventEmitterClass } from './lib/create-event-emitter-class';
import { LegalNoticesClientConfig } from './interfaces/LegalNoticesClientConfig';
import { CommonTemplateDataProvider } from './lib/CommonTemplateDataProvider';
import { FirstRunPopup, FirstRunPopupEvents } from './ui/FirstRunPopup';
import { ClientEvents, LegalNoticesClientEventListeners } from './ClientEvents';
import { hasUserChangedSettings } from './lib/has-user-changed-settings';


interface CompliantFunctionCallResult<T> {
   readonly returnValue: UnwrapPromise<T>;
   readonly isAllowed: boolean;
}

export interface ChromeOverrideOptions {

   /**
    * The legal notices client will render all of the _content_ of the popup/modal as
    * children of this `el` element.
    */
   el: HTMLElement;

   /**
    * The popup/modal content may contain buttons or links that should cause the popup or
    * modal to close when clicked. The legal notices client will call this function
    * whenever it determines that the popup/modal should close. Therefore, calling this
    * function should close your custom popup/modal implementation.
    */
   close: () => void;
}

export interface OpenModalFromPopupChromeOverrideOptions extends ChromeOverrideOptions {

   /**
    * If provided, this function is called right before the settings modal is opened from
    * a link within the first-run popup. This option is useful when you want to override
    * both the settings modal's chrome and you want to immediately show the custom modal
    * chrome when the user clicks a link that opens the settings modal.
    */
   onBeforeOpenSettingsModal?: () => void;
}

export class LegalNoticesClient extends createEventEmitterClass<LegalNoticesClientEventListeners>() {

   protected _config: LegalNoticesClientConfig;

   protected _isShowingFirstRunPopup = false;
   protected _isShowingSettingsModal = false;
   protected _templateDataProvider = new CommonTemplateDataProvider();

   public constructor(options?: Partial<LegalNoticesClientConfig>) {
      super();
      this._config = Object.assign({}, DEFAULT_CONFIG, options || {});
   }

   /**
    * Displays the "first-run" popup on the page, if necessary. The first-run popup
    * explains the site/app's cookie policy and prompts the user to opt in or out of the
    * use of cookies on the site. If the user has already responded to the popup's prompt,
    * calling this method will not display the popup.
    *
    * @param popupChromeOverrideOptions - If this parameter is provided, the legal
    * notices client will not display the default first-run popup. Instead, it will render
    * all of the _content_ of the first-run popup as children of the
    * `popupChromeOverrideOptions.el` element. This is useful if, for example, you don't
    * want to display the content inside of the default popup. If this parameter is not
    * provided, the client will render and display the default popup, adding it to the DOM
    * as the first child of the `body` element.
    *
    * @param modalChromeOverrideOptions - The first-run popup contains a link that shows
    * the settings modal when clicked. When the link is clicked, the client will first
    * call `popupChromeOverrideOptions.close` to close the first-run popup, then it will
    * show the settings modal. If you would like to override the settings modal's default
    * "chrome", pass this parameter. If passed, the legal notices client will not display
    * the default settings modal. Instead, it will render all of the _content_ of the
    * settings modal as children of the `modalChromeOverrideOptions.el` element. NOTE:
    * the legal notices client will only use this `ChromeOverrideOptions` when opening the
    * settings modal in response to a click in the first-run popup. If you manually call
    * the {@link showSettings} method, you must also pass the `ChromeOverrideOptions` to
    * {@link showSettings} if you want to override the settings modal chrome.
    */
   public run(popupChromeOverrideOptions?: ChromeOverrideOptions, modalChromeOverrideOptions?: OpenModalFromPopupChromeOverrideOptions):
      void {

      this._validateChromeOverrideOptions(popupChromeOverrideOptions);
      this._validateChromeOverrideOptions(modalChromeOverrideOptions);

      this._shouldShowFirstRunPopup()
         .then((shouldShowPopup) => {
            if (!shouldShowPopup) {
               return;
            }
            const popup = new FirstRunPopup({
               eventEmitter: this,
               clientConfig: this._config,
               uiOverrideOptions: { popup: popupChromeOverrideOptions, modal: modalChromeOverrideOptions },
               templateDataProvider: this._templateDataProvider,
            });

            popup.show();

            popup.on(FirstRunPopupEvents.SETTINGS_REQUESTED, () => {
               if (modalChromeOverrideOptions && modalChromeOverrideOptions.onBeforeOpenSettingsModal) {
                  modalChromeOverrideOptions.onBeforeOpenSettingsModal();
               }
               this.showSettings(modalChromeOverrideOptions);
            });

            // If the caller provides popupChromeOverrideOptions, we are no longer able to
            // keep track of whether there is a first-run popup showing or not. For
            // example, they may implement the first-run popup as a custom modal with
            // their own "close" button. The legal notices client would have no way to
            // know when that close button is clicked. So, we do not keep track of the
            // state of `_isShowingFirstRunPopup` when we receive ChromeOverrideOptions.
            this._isShowingFirstRunPopup = !popupChromeOverrideOptions;
         })
         .catch((e) => {
            this.emit(ClientEvents.ERROR, e);
         });
   }

   /**
    * Displays the privacy settings modal where a user can opt in or out of categories of
    * cookie data.
    *
    * @param chromeOverrideOptions - If this parameter is provided, the legal notices
    * client will not display the default settings modal. Instead, it will render all of
    * the _content_ of the settings modal as children of the provided
    * `_chromeOverrideOptions.el` element. This is useful if, for example, you don't want
    * to display the content inside of the default modal. If this parameter is not
    * provided, the client will render and display the default modal, adding it to the DOM
    * as the last child of the `body` element.
    */
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   public showSettings(chromeOverrideOptions?: ChromeOverrideOptions): void {
      if (this._isShowingSettingsModal) {
         return;
      }

      this._validateChromeOverrideOptions(chromeOverrideOptions);

      const modal = new SettingsModal({
         eventEmitter: this,
         clientConfig: this._config,
         chromeOverrideOptions,
         templateDataProvider: this._templateDataProvider,
      });

      modal.show();

      if (!chromeOverrideOptions) {
         // We need to set this flag immediately rather than waiting for a SETTINGS_SHOWN
         // event so that any calls to `showSettings` on the same turn of the event loop
         // will return early and not re-show the modal
         this._isShowingSettingsModal = true;
         this.once(ClientEvents.SETTINGS_CLOSED, () => { this._isShowingSettingsModal = false; });
      }
   }

   /**
    * Checks whether the user has opted into storage of a given {@link CookieDataTypes}
    * category.
    *
    * @param dataType
    *
    * @return `Promise<true>` if the user has opted into storage of the given
    * {@link CookieDataTypes} category, `Promise<false>` if the user has opted out, and
    * `Promise<undefined>` if the user has not provided a preference yet.
    */
   public async canPersistType(dataType: CookieDataTypes): Promise<boolean | undefined> {
      const settings = await this._config.storage.get();

      return settings[dataType];
   }

   /**
    * Update the Watchtower language code for the legal notices client. Updating the
    * language code does not update the language of the text within the first-run popup or
    * the settings modal. You should change the language _before_ calling
    * {@link LegalNoticesClient.run} or {@link LegalNoticesClient.showSettings}.
    *
    * @param languageCode
    */
   public setLanguageCode(languageCode: string): void {
      this._config.languageCode = languageCode;
   }

   /* eslint-disable max-len */
   /**
    * Provides a convenient interface for calling a function only when the user's privacy
    * settings allow access to data of a given {@link CookieDataTypes} `dataType`.
    *
    * For example, you may want to only store a value in localStorage if the user's
    * privacy settings allow access to the `FUNCTIONAL` type:
    *
    * ```ts
    * let lnc = new LegalNoticesClient();
    *
    * lnc.callIfAllowed(CookieDataTypes.FUNCTIONAL, () => { localStorage.setItem('PREFERRED_THEME', 'dark'); });
    * ```
    *
    * The provided `callback` function **will not be called** if the privacy setting for the
    * given {@link CookieDataTypes} `dataType` is `false` (meaning the user has opted-out of
    * the cookie category) or `undefined` (meaning the user has not yet responded to the
    * first-run popup or settings modal prompts).
    *
    * This method returns a Promise that is resolved after the provided `callback`
    * function is called. If the `callback` is not called because the user's privacy
    * settings do not allow access to the provided {@link CookieDataTypes} `dataType`, the
    * Promise resolves immediately.
    *
    * The Promise is resolved with an object that has two properties:
    *
    *    1. `returnValue` - The value that the `callback` function returned when it was
    *       called. If `callback` was not called, this value is `undefined`.
    *    2. `isAllowed` - The value of the {@link CookieSettings} for the given
    *       {@link CookieDataTypes} `dataType`. May be either `true`, `false`, or
    *       `undefined`, depending on whether and how the user has responded to the
    *       first-run popup or settings modal prompts.
    *
    * You can optionally provide a default value that will be returned as the
    * `returnValue` property if the user's privacy settings do not allow access to the
    * {@link CookieDataTypes} type. Please note that this default is _not_ used as a
    * fallback for when the provided `callback` function does not return a value. If the
    * user's privacy settings allow it, this method will just return whatever the provided
    * `callback` function returns, even if the `callback` function does not return
    * anything.
    *
    * Example usage:
    *
    * ```ts
    * let lnc = new LegalNoticesClient();
    *
    * lnc.callIfAllowed(CookieDataTypes.FUNCTIONAL, () => { return localStorage.get('PREFERRED_THEME'); }, 'light')
    *    .then((result) {
    *       // result.returnValue defaults to 'light' if the user has declined 'FUNCTIONAL'
    *       // cookies, or if the user has not responded yet.
    *       document.body.classList.add(`theme-${result.returnValue}`);
    *
    *       // If the user has either declined FUNCTIONAL cookies or has not responded,
    *       // add an additional CSS class that lets us know that the user has not
    *       // specified a theme preference.
    *       if (!result.isAllowed) {
    *          document.body.classList.add(`theme-noPreferenceSpecified`);
    *       }
    *    });
    * ```
    */
   /* eslint-enable max-len */
   public async callIfAllowed<T>(dataType: CookieDataTypes, callback: () => T):
      Promise<CompliantFunctionCallResult<T | void>>;
   public async callIfAllowed<T, U>(dataType: CookieDataTypes, callback: () => T, defaultReturnValue: U):
      Promise<CompliantFunctionCallResult<T | U>>;
   public async callIfAllowed<T, U = T>(dataType: CookieDataTypes, callback: () => T, defaultReturnValue?: U):
      Promise<CompliantFunctionCallResult<T | U | void>> {

      const isAllowed = await this.canPersistType(dataType);

      if (isAllowed) {
         const value = await Promise.resolve().then(() => { return callback(); });

         return {
            returnValue: value as UnwrapPromise<T>,
            isAllowed,
         };
      }

      if (!isUndefined(defaultReturnValue)) {
         return {
            returnValue: defaultReturnValue as UnwrapPromise<U>,
            isAllowed: false,
         };
      }

      return {
         returnValue: undefined,
         isAllowed: false,
      };
   }

   protected async _shouldShowFirstRunPopup(): Promise<boolean> {
      if (this._isShowingFirstRunPopup) {
         return false;
      }

      const settings = await this._config.storage.get();

      return !hasUserChangedSettings(settings);
   }

   protected _validateChromeOverrideOptions(chromeOverrideOptions: ChromeOverrideOptions | undefined): void {
      if (chromeOverrideOptions) {
         // Validate chromeOverrideOptions. While TypeScript ensures that clients can only
         // pass in valid chromeOverrideOptions, some clients may use JavaScript and thus
         // be able to pass chromeOverrideOptions of any shape.
         if (typeof chromeOverrideOptions.close !== 'function') {
            throw new Error(
               'chromeOverrideOptions.close is required and should be a function, ' +
               'but was: ' + (typeof chromeOverrideOptions.close)
            );
         }
         if (!chromeOverrideOptions.el || !isString(chromeOverrideOptions.el.nodeName)) {
            throw new Error(
               'chromeOverrideOptions.el is required and should be a HTMLElement, ' +
               'but was: ' + (typeof chromeOverrideOptions.el)
            );
         }
      }
   }
}
