import { LanguageSummary } from './interfaces/LanguageSummary';
import { LegalNoticesClientConfig } from './interfaces/LegalNoticesClientConfig';
import { DefaultCookieSettingsStorageProvider } from './DefaultCookieSettingsStorageProvider';

// NOTE: If these default values change, please update all of the places that the defaults
// are referenced in our documentation, e.g. getting-started.md.

/**
 * Default configuration values for the legal notices client.
 */
export const DEFAULT_CONFIG: LegalNoticesClientConfig = {
   languageCode: 'E',
   languageClassesMaker: (languageSummary: LanguageSummary) => {
      return `ml-${languageSummary.languageCode} ms-${languageSummary.script} dir-${languageSummary.direction}`;
   },
   storage: new DefaultCookieSettingsStorageProvider(),
   i18nURL: 'https://b.jw-cdn.org/apis/i18n',
   finderURL: 'https://www.jw.org/open',
};
